import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import AddFunds from "./Add"
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addStripePayment } from '../../../redux/actions/stripePaymentAction';


const stripePromise = loadStripe("pk_test_9Mkk2IizHw9TE3cSOzKbLxoV00xALT39G0");

function Payment({ updateBalance, type }) {
  const [clientSecret, setClientSecret] = useState('')
  const dispatch = useDispatch()
   const { userData } = useSelector((state) => state.auth);
  const [amountStripe, setamountStripe] = useState({
    user_id: null,
    amount: 0,
    currency: "usd",
    payment_method_types: ["card"]
  });
 const handleall = () => {
    console.log("go")
  }
  const handleTransferStripe = async (e) => {
    e.preventDefault();
     const payload = {
    user_id: Number(userData?.id),
    amount: Number(amountStripe.amount),
    currency: "usd",
    payment_method_types: ["card"],
    };

    console.log(payload);
    
    try {
      const action = await addStripePayment(dispatch, payload);
          console.log("Client secret received:", action.payload);
          setClientSecret(action.payload); 
    } catch (error) {
      
    }
  }
  // const options = {
  //   clientSecret:`${clientSecret}`
  // }
const options = {
     mode: 'payment',
    currency: 'usd',
    amount: 1000
  };

  const handleChangeStripe = (e) => {
   const { name, value } = e.target;
   setamountStripe((prev) => ({
     ...prev,
     [name]: value
   }));
 };

  
  
  return (
    
    
    <Elements stripe={stripePromise} options={options} >
      <AddFunds type={type} handleTransferStripe={handleTransferStripe} handleall={handleall} updateBalance={updateBalance} handleChangeStripe={handleChangeStripe} amountStripe={amountStripe.amount} clientSecret={clientSecret} />
    </Elements>
    
  )
}

export default Payment
