import React, { useEffect, useState } from "react";
import HomeModal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { getJobsRecommendationAction } from "../../../../redux/actions/jobActions";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import JobCard from "../AllJobs/JobCard";
import { saveBusinessRef } from "../../../../redux/actions/businessActions";

const Recommended = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
   const [totalJobs, setTotalJobs] = useState(0);
  const [section, setSection] = useState("details");
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  
  const { jobsData } = useSelector(({ job }) => job);
  const { jobId, refLink } = useParams();
  const { jobs, count } = jobsData;
 const jobsPerPage = 20;
  // useEffect(() => {
  //   page === 1 ? setLoading(true) : setLoadingMore(true);
  //   Promise.resolve(getJobsRecommendationAction(dispatch, page)).finally(() =>
  //     page === 1 ? setLoading(false) : setLoadingMore(false)
  //   );
  // }, [dispatch, page]);
   const totalPages = Math.max(1, Math.ceil(totalJobs / jobsPerPage));
  useEffect(() => {
      const fetchJobs = async () => {
        console.log("Fetching jobs for page:", page);
  
        setLoading(page === 1);
        setLoadingMore(page > 1);
  
        try {
          const response = await getJobsRecommendationAction(dispatch, page, jobsPerPage);
  
          if (response && response.total) {
            console.log("Total jobs fetched:", response.total);
            setTotalJobs(response.total);
          } else {
            console.log("No total jobs returned from the API.");
            setTotalJobs(0);
          }
        } catch (error) {
          console.error("Error fetching jobs:", error);
        }
  
        setLoading(false);
        setLoadingMore(false);
      };
  
      fetchJobs();
    }, [dispatch, page, jobsPerPage]);

  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };
   const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      console.log("Changing page to:", newPage);
      setPage(newPage);
    }
  };

  return (
    <div className="row position-relative mt-3 align-items-stretch">
      {jobId && (
        <HomeModal
          setSection={setSection}
          section={section}
          open={jobId}
          handleClose={() => history.push("/home")}
          isBusiness={pathname.includes("biz") == true ? true : false}
          saveForLater={saveForLater}
        />
      )}
      {loading && !jobs[0] && <SectionLoader />}
      {!loading && !jobs[0] && (
        <EmptyState text="There are no recommended jobs" />
      )}
      {jobs && jobs[0] && (
        <>
          {jobs.map((job, index) => (
            <div
              key={index.toString()}
              className="col-12 col-md-6"
              style={{
                padding: "10px",
                maxHeight: "280px",
              }}
            >
              <JobCard job={job} />
            </div>
          ))}
        {/*  
            <div className="mx-auto mt-3 w-75">
              <CustomButton
                handleClick={() => setPage(page + 1)}
                text={loadingMore ? <ButtonLoader /> : "Load More"}
              />
            </div>
         */}
        </>
      )}

           {totalPages >= 1 && (
                <div className="container" style={{marginTop: '5%'}}>
                <div className="row clearfix">
                  <div className="mx-auto col-md-2 mt-3 w-75"></div>
        
                  <div className="mx-auto col-md-2 mt-3 w-75">
                    <CustomButton
                      handleClick={() => handlePageChange(page - 1)}
                      text={loadingMore ? <ButtonLoader /> : "Prev"}
                      disabled={page === 1 || loading}
                    />
                  </div>
        
                  <div className="col-md-2 text-center" style={{ marginTop: "2.5%" }}>
                    <p>
                      Page: {page} of {totalPages}
                    </p>
                  </div>
        
                  <div className="mx-auto col-md-2 mt-3 w-75">
                    <CustomButton
                      handleClick={() => handlePageChange(page + 1)}
                      text={loadingMore ? <ButtonLoader /> : "Next"}
                      disabled={page === totalPages || loading}
                    />
                  </div>
        
                  <div className="mx-auto col-md-2 mt-3 w-75"></div>
                </div>
                </div>
              )}

    </div>
  );
};

export default Recommended;
