import {
  FormControl,
  MenuItem,
  Select,
  Modal,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import AddedCard from "./AddedCard";
import './index.css';
import StripeLogo from "../../../../assets/icons/stripelogo.png";
import PaystackLogo from "../../../../assets/icons/paystackicon.png";
import CloseIcon from "../../../../assets/icons/close-icon-png.jpeg";

import {
  createCardToken,
  getCards,
  resetCards,
  fundWallet,
  addCardPaystack,
  fundWalletWithPaystack,
  fundWalletWithGPAY
} from "../../../../redux/actions/walletActions";
import { usePaystackPayment } from "react-paystack";
import Account from "../Add/Account";
import GooglePayButton from '@google-pay/button-react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import CustomTextArea from "../../../../components/CustomTextArea";

import { addPayment } from "../../../../redux/actions/payment";
import { RepeatOneSharp } from "@material-ui/icons";
import { addStripePayment } from "../../../../redux/actions/stripePaymentAction";
import PaymentSuccess from "./PaymentTemplate";


const AddFunds = ({ type, updateBalance, handleTransferStripe,handleChangeStripe, clientSecret,
amountStripe }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openPay, setOpenPay] = useState(false);

  const [loading, setLoading] = useState(false);
  const [paying, setPaying] = useState(false);

  const { userData } = useSelector((state) => state.auth);
  const [stripeOrPaystackOption, setStripeOrPaystackOption] = useState(false);
  const [page, setPage] = useState(1);
  const [reloadCards, setReloadCards] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    card_name: "",
    card_number: "",
    exp_month: "1",
    exp_year: "2020",
    cvc: "",
  });

  const [amount, setAmount] = useState(0.0);
  const [openStripe, setOpenStripe] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState('1.00');
  
  
  const { cards } = useSelector((state) => state.wallet);
  const [openDialog, setOpenDialog] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [transfers, setTransfers] = useState({
    email: userData?.email,
    amount: 0,
    description: "",
    redirect_url: ""
  });

  const handleCloseModalStripe = () => {
    setOpenStripe(false);
  };

  const handleOpenModalStripe = () => {
    setOpenStripe(true);
  };

 
// const handlePayment = async (e) => {
//   e.preventDefault();

//   try {
    

//     const response = await stripe.confirmCardPayment(action.payload,{
//       payment_method: {
//         card: elements.getElement(CardElement),
//         billing_details: {
//           email: userData?.email
//         }
    
//   }
//     })
//     if (response.error) {
//       cogoToast.error("payment failed")
//     }
    
//   } catch (error) {
//     console.error("Error creating payment intent:", error);
//     cogoToast.error("Error creating payment intent: " + error.message);
//   }
// };

// const handlePaymentSubmit = async (e) => {
//   e.preventDefault();

//   if (!stripe || !elements) {
//     cogoToast.error("Stripe.js is not loaded yet. Please try again.");
//     return;
//   }

//   if (!clientSecret) {
//     cogoToast.error("Payment Intent client secret is missing.");
//     return;
//   }

//   try {
//     const result = await stripe.confirmPayment({
//       elements,
//         confirmParams: {
//         return_url: "https://your-success-url.com",
//       },
//     })
//     // const { error } = await stripe.confirmPayment({
//     //  card: elements.getElement(CardElement),
//     //   confirmParams: {
//     //     return_url: "https://your-success-url.com",
//     //   },
//     // });

//     if (result.error) {
//       cogoToast.error(`Payment failed: ${result.error.message}`);
//     } else {
//       cogoToast.success("Payment successful!");
//     }
//   } catch (err) {
//     console.error("Payment submission error:", err);
//     cogoToast.error("An unexpected error occurred. Please try again.");
//   }
//   };
  
  const [paymentMessage, setPaymentMessage] = useState("")
  const [isProcessing,setIsProcessing]= useState(false)
  
//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       setPaymentMessage('Stripe.js has not loaded yet.');
//       setIsProcessing(false);
//       return;
//     }

//     setLoading(true);

 
//     const { error: submitError } = await elements?.submit();
//     if (submitError) {
//       console.error(submitError.message);
//       setLoading(false);
//       return;
//     }
//     try {
     
//       const payload = {
//     user_id: Number(userData?.id),
//     amount: Number(amountStripe),
//     currency: "usd",
   
//     };

//       const action = await addStripePayment(dispatch, payload);
//                 console.log("Client secret received:", action.payload);
//       const clientSecret = action.payload;
     
//       const { error } = await stripe?.confirmPayment({
//         elements,
//         clientSecret,
//         confirmParams: {
//           return_url: `${window.location.origin}/wallet/add`
//         }
        
//       });

//       if (error) {
//         console.error(error.message);
//         setLoading(false);
//       } else {
     
// console.log('Payment successful!');
// cogoToast.success("Payment Succesful")

//         setPaymentMessage('Payment successful!');
       
       
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
const handleSubmit = async (event) => {
  event.preventDefault();

  if (!stripe || !elements) {
    setPaymentMessage("Stripe.js has not loaded yet.");
    cogoToast.error("Stripe.js has not loaded yet.");
    setIsProcessing(false);
    return;
  }

  setLoading(true);

  try {
    const { error: submitError } = await elements?.submit();
    if (submitError) {
      console.error(submitError.message);
      cogoToast.error(submitError.message);
      setLoading(false);
      return;
    }

    const payload = {
      user_id: Number(userData?.id),
      amount: Number(amountStripe),
      currency: "usd",
    };

    const action = await addStripePayment(dispatch, payload);
    console.log("Client secret received:", action.payload);
    const clientSecret = action.payload;

    const { error } = await stripe?.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
      },
    });
    cogoToast.success("Payment Successful")

    if (error) {
      console.error("Payment error:", error.message);
      cogoToast.error(`Payment failed: ${error.message}`);
      setLoading(false);
    } else {
      console.log("Payment successful!");
      cogoToast.success("Payment successful!");

      setPaymentMessage("Payment successful!");
    }
  } catch (error) {
    console.error("An unexpected error occurred:", error.message);
    cogoToast.error(`Unexpected error: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };







  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setTransfers((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    setReloadCards(true);
    Promise.resolve(getCards(dispatch, page)).finally(() =>
      setReloadCards(false)
    );
  }, [dispatch, page]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAmount = ({ target: { id, value } }) => {
    setAmount(value);
  };

  const handleChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    setCardDetails({ ...cardDetails, ...newValue });
  };

  const handleSelect = ({ target: { name, value } }) => {
    const changed = {};
    changed[name] = value;
    setCardDetails({ ...cardDetails, ...changed });
  };

  const addCardToStripe = async () => {
    setLoading(true);
    resetCards(dispatch, page);
    const cardData = {
      "card[name]": cardDetails.card_name,
      "card[number]": cardDetails.card_number,
      "card[exp_month]": cardDetails.exp_month,
      "card[exp_year]": cardDetails.exp_year,
      "card[cvc]": cardDetails.cvc,
    };

    setReloadCards(true);
    Promise.resolve(createCardToken(dispatch, cardData)).finally(() => {
      getCards(dispatch, page);
      setCardDetails({
        card_number: "",
        exp_month: "1",
        exp_year: "2020",
        cvc: "",
      });
      setLoading(false);
      setOpen(false);
      setReloadCards(false);
    });

    setLoading(false);
  };

  const refreshList = async () => {
    resetCards(dispatch, page);
    getCards(dispatch, page);
  };

  const handleTransfer = async (e) => {
    e.preventDefault();
    if (!transfers.amount || !transfers.description) {
      cogoToast.error("All fields are required");
      return;
    }
    if (transfers.amount < 1000) {
      cogoToast.error("Amount must be greater than or equal to 1000");
      return;
    }
    const payload = {
      email: userData?.email,
      amount: Number(transfers.amount),
      description: transfers.description,
      redirect_url: ""
    };
    try {
      const action = await addPayment(dispatch, payload);
      if (action && action.payload && action.payload.valid) {
        cogoToast.success(action.payload.message);
        const checkoutLink = action.payload.entity?.checkoutLink;
        if (checkoutLink) {
          window.open(checkoutLink, "_blank");
        } else {
          cogoToast.error("Checkout link is not available");
        }
      }
    } catch (error) {
      cogoToast.error("Error processing payment: " + error.message);
    }
  };
const handleCloseModal = () => {
     setOpenDialog(false)
        }
        const handleOpen = () => {
          setOpenDialog(true)
        }
  const handleFundWallet = async () => {
    // Get default card
    const card = cards && cards[0].filter((card) => card.isDefault === "1");
    // console.log("Default Card", card);

    const cardDetails = card[0].card;
    const auth_code = JSON.parse(cardDetails).authorization_code;
   
    setPaying(true);
    // If no default card exist
    if (!card[0]) {
      alert("Select a default card first.");
    } else {
      // console.log("fundData", fundData);
      if (type === "stripe") {
        const fundDataStripe = {
          amount: parseFloat(amount),
          card_id: card[0].id,
        };
        await fundWallet(dispatch, fundDataStripe);
      } else if (type === "paystack") {
        const fundDataPaystack = {
          amount: parseFloat(amount),
          authorization_code: auth_code,
          email: userData && userData["email"],
        };
        
        await fundWalletWithPaystack(dispatch, fundDataPaystack);
        updateBalance();
      }
      // console.log("funded");
    }
    setPaying(false);
    setAmount(0.0);
  };

  const onSuccess = (reference) => {
    // console.log(reference);
    const ref = {
      reference: reference.reference,
      type:"paystack"
    };

    const addCard = async () => {
      addCardPaystack(dispatch, ref);
      refreshList();
    };
    addCard();
  };

  const onClose = () => {
    // console.log("closed");
  };

    const processPayment = async (paymentData)=> {
    const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
    const fundDataGpay = {
      amount: parseFloat(amount),
      authorization_code: paymentToken,
      email: userData && userData["email"],
    };

    await fundWalletWithGPAY(dispatch, fundDataGpay);
  }


  const transfer = () => {};
  return (
    <div className="m-5 pb-5">
      <div className="pb-2">
        {/* <Account transfer={transfer} refreshList={refreshList} /> */}
      </div>

      <h3
        className="p-2"
        style={{
          textAlign: "center",
        }}
      >
        Payment Options
      </h3>
     
       <Dialog
        open={openDialog}
        onClose={handleCloseModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "35rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <DialogContent>
          <h5>Add your Details</h5>
          <form className="pb-5" onSubmit={(e)=>handleTransfer(e)}>
            <CustomInput
              type="text"
              label="Amount"
              id="amount"
              name="amount"
              value={transfers.amount}
              onChange={(e)=>handleChanges(e)}
            />

                  <CustomTextArea
                    id="description"
                    name="description"
                    type="text"
                    value={transfers.description}
                       onChange={(e)=>handleChanges(e)}
                  
                  />
           
  
            

            <CustomButton
              backgroundColor="#3A6ED4"
              color="#fff"
              type="submit"
              text="Submit"
              additionalClass="mt-5 mb-2"
              
             
            />
          </form>
        </DialogContent>
      </Dialog>

<Dialog
      open={openStripe}
      onClose={handleCloseModalStripe}
      aria-labelledby="form-dialog-title"
      PaperProps={{
         style: {
      width: "35rem",
      borderRadius: "1rem",
      padding: "0.75rem",
    },
      }}
    >
        <DialogContent  style={{
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      overflow: "auto", // Allow content to scroll if it grows too large
      maxHeight: "80vh", // Prevent the modal from exceeding the viewport height
    }}>
          
          <form onSubmit={handleSubmit} className="CheckoutForm flex flex-col gap-4" style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      
          }}>
            
           
 <CustomInput
        type="text"
        label="Amount"
        id="amount"
        name="amount"
        placeholder="Enter amount in USD"
        value={amountStripe}
        onChange={(e) => handleChangeStripe(e)}
            />
            
               {/* <PaymentElement/>
         
            <CustomButton
        backgroundColor="#3A6ED4"
        color="#fff"
        type="submit"
        text="Submit"
        additionalClass="mt-5 mb-2"
      /> */}
             <div className="payment-container"
         style={{
          width: "100%",
          marginBottom: "1rem",
        }}>
          <PaymentElement  />
                </div>
             
      
        <CustomButton
          backgroundColor="#3A6ED4"
          color="#fff"
          type="submit"
          text="Pay Now"
          additionalClass="mt-5"
        />

          </form>
  {/* {!clientSecret ? (
    <form className="pb-5" onSubmit={(e) => handleTransferStripe(e)}>
      <h5 className="pb-3">Add Amount</h5>
      <CustomInput
        type="text"
        label="Amount"
        id="amount"
        name="amount"
        placeholder="Enter amount in USD"
        value={amountStripe}
        onChange={(e) => handleChangeStripe(e)}
      />
      <CustomButton
        backgroundColor="#3A6ED4"
        color="#fff"
        type="submit"
        text="Submit"
        additionalClass="mt-5 mb-2"
      />
    </form>
  ) : (
    
      <form onSubmit={(e) => handlePaymentSubmit(e)} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
       
        <div style={{ width: "100%", maxWidth: "100%", height: "40vh", marginBottom: "20px", display: "flex", justifyContent: "center" }}>
          <PaymentElement options={clientSecret}  />
                </div>
                <div id="payment-element"></div>
     
        <CustomButton
          backgroundColor="#3A6ED4"
          color="#fff"
          type="submit"
          text="Pay Now"
          additionalClass="mt-5"
        />
      </form>

  )} */}
  
        </DialogContent>
        
    </Dialog>      {/* { userData['country'] != 160  ? */}
      <div>
        { !stripeOrPaystackOption ? 
        <button className="stripeBtn"
        //  onClick={()=>{
        //   setStripeOrPaystackOption(true)
            // }}
            onClick={handleOpenModalStripe}
        >
        <img src={StripeLogo} width='60' 
        
        />
        </button> 
        :<p></p>
        }
      </div> 
        :
       <div>
         
        <button style={{padding: "10px", borderRadius: "10px"}} className="paystackBtn "
         onClick={handleOpen}
        >
         <b>Jara Pay </b>
        </button> 
        
      </div>  
        {/* }  */}
                   {/* { 
        !stripeOrPaystackOption ? 
      <div className="paymentmethodContainer">
         <CustomInput
      type="number"
      label="Enter Amount"
      id="paymentAmount"
      value={paymentAmount}
      onChange={handlePaymentAmountChange} 
    />
      <GooglePayButton
          environment="TEST"
      
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: ['AMEX', 'DISCOVER','MASTERCARD', 'VISA']
                },
                tokenizationSpecification: {
                  type: 'PAYMENT_GATEWAY',
                  parameters: {
                    'gateway': 'stripe',
                    'stripe:version': '2016-07-06',
                    // 'stripe:publishableKey': 'pk_live_g62PWzL7fEzgY6Q5KxCAfqLD00bNgeHyXk'
                    'stripe:publishableKey': 'pk_test_9Mkk2IizHw9TE3cSOzKbLxoV00xALT39G0'
                  }
                }
              },
            ],
            // merchantInfo: {
            //   merchantId: 'BCR2DN6TZ7R4JRIJ',
            //   merchantName: 'workbrook',
            // },
            merchantInfo: {
              merchantId: '1234567890'
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPriceLabel: 'Total',
              totalPrice: `${paymentAmount}`,
              currencyCode: 'USD',
              countryCode: 'US',
            },
          }}
          onLoadPaymentData={paymentRequest => {
            console.log('load payment data', paymentRequest);
            processPayment(paymentRequest)
          }}
          style={{
            width:'100%',
            textAlign:'center'
          }}
          buttonColor={'blue'}
          buttonType={'plain'}
          buttonSizeMode={'fill'}
          buttonLocale={'en'}

        />
      </div>
        :<p></p>
      }  */}
      {/* {
  !stripeOrPaystackOption ? (
    <div className="paymentmethodContainer">
      <CustomInput
        type="number"
        label="Enter Amount"
        id="paymentAmount"
        value={paymentAmount}
        onChange={handlePaymentAmountChange}
      />
      <GooglePayButton
        environment="TEST"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["AMEX", "DISCOVER", "MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "stripe",
                  "stripe:version": "2016-07-06",
                  "stripe:publishableKey":
                    "pk_test_9Mkk2IizHw9TE3cSOzKbLxoV00xALT39G0",
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: "12345678901234567890",

            merchantName: "Test Merchant", 
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: `${Number(paymentAmount).toFixed(2)}`, 
            currencyCode: "USD",
            countryCode: "US",
          },
        }}
        onLoadPaymentData={(paymentRequest) => {
          console.log("Payment Data Loaded", paymentRequest);
          processPayment(paymentRequest);
        }}
        onError={(err) => {
          console.error("Google Pay Error", err);
        }}
        style={{
          width: "100%",
          textAlign: "center",
        }}
        buttonColor="blue"
        buttonType="plain"
      />
    </div>
  ) : (
    <p></p>
  )
} */}
    

      {
   stripeOrPaystackOption ?
      <div className='paystackstripe'>
   
      <div className="pull-rightx">

      <a onClick={ ()=>{
        setStripeOrPaystackOption(false)
      }} href="#">
      Switch Payment Option
      </a>
      </div>
     
      <h5 style={{ color: "#3a6ed4", marginBottom: "30px", marginTop:'5%' }}>
        Choose A Card To Pay With 
      </h5>
    

      
      {reloadCards && !cards && <SectionLoader />}
      {!reloadCards && !cards && (
        <EmptyState text="You have not added any card" />
      )}
      {cards &&
        cards[0].map((card) => (
          <AddedCard
            key={card.id}
            card={card}
            img={card.brand}
            refreshList={refreshList}
          />
        ))}
        
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "35rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        <DialogContent>
          <h5>Add your card</h5>
          <form className="pb-5">
            <CustomInput
              type="text"
              label="Name on Card"
              id="card_name"
              name="card_name"
              value={cardDetails.card_name}
              onChange={handleChange}
            />

            <CustomInput
              label="Card Number"
              id="card_number"
              name="card_number"
              type="number"
              value={cardDetails.card_number}
              onChange={handleChange}
            />
  
            <div className="d-flex justify-content-between align-items-end">
              <FormControl variant="standard" className="custom-input w-25">
                <label className="d-block mb-0">Expiry Date</label>
                <Select
                  id="exp_month"
                  name="exp_month"
                  label="Expiry Month"
                  onChange={handleSelect}
                  defaultValue="1"
                >
                  <MenuItem value="1">Jan</MenuItem>
                  <MenuItem value="2">Feb</MenuItem>
                  <MenuItem value="3">Mar</MenuItem>
                  <MenuItem value="4">Apr</MenuItem>
                  <MenuItem value="5">May</MenuItem>
                  <MenuItem value="6">Jun</MenuItem>
                  <MenuItem value="7">Jul</MenuItem>
                  <MenuItem value="8">Aug</MenuItem>
                  <MenuItem value="9">Sep</MenuItem>
                  <MenuItem value="10">Oct</MenuItem>
                  <MenuItem value="11">Nov</MenuItem>
                  <MenuItem value="12">Dec</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="standard" className="w-25">
                <Select
                  id="exp_year"
                  label="Expiry Year"
                  name="exp_year"
                  onChange={handleSelect}
                  defaultValue="2020"
                >
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2022">2022</MenuItem>
                  <MenuItem value="2023">2023</MenuItem>
                  <MenuItem value="2024">2024</MenuItem>
                  <MenuItem value="2025">2025</MenuItem>
                  <MenuItem value="2026">2026</MenuItem>
                  <MenuItem value="2027">2027</MenuItem>
                  <MenuItem value="2028">2028</MenuItem>
                  <MenuItem value="2029">2029</MenuItem>
                  <MenuItem value="2030">2030</MenuItem>
                  <MenuItem value="2031">2031</MenuItem>
                </Select>
              </FormControl>
              <div>
                <CustomInput
                  type="number"
                  label="CCV"
                  id="cvc"
                  name="cvc"
                  onChange={handleChange}
                />
              </div>
            </div>

            <CustomButton
              backgroundColor="#3A6ED4"
              color="#fff"
              text="ADD CARD"
              additionalClass="mt-5 mb-2"
              handleClick={addCardToStripe}
              loading={loading}
              disabled={[!cardDetails.card_number, !cardDetails.cvc].includes(
                true
              )}
            />
          </form>
        </DialogContent>
      </Dialog>

      {cards && cards[0] && (
        <form className="pb-5">
          <h5>Fund Wallet</h5>
          <CustomInput
            name="amount"
            id="amount"
            label={`Enter Amount in ${
              type == "stripe" ? "Dollars(USD)" : "Naira(NGN)"
            }`}
            isRequired="true"
            type="number"
            value={amount}
            onChange={handleAmount}
          />
          <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            text="PAY"
            additionalClass="mt-5 mb-2"
            loading={paying}
            handleClick={handleFundWallet}
            disabled={[!amount].includes(true)}
          />

         
 

          

        </form>
      )} 
      {/**   {type && type == "stripe" && ( 
       * 
       *         {type && type == "paystack" && (
       * 
      */}
      {/* <div className="d-flex justify-content-end pb-4 pt-4">
        {userData && userData["country"] != 160 && (
          <Link onClick={() => setOpen(true)}>+ Add New Card (Stripe)</Link>
        )}
        {userData && userData["country"] == 160 && (
          <div className="d-flex flex-column justify-content-end text-right">
            <div>
              <Link onClick={addPaystackCard}>+ Add New Card (Paystack)</Link>
            </div>
            <div>
              (Based on local regulations, you will be debited &#8358;50($0.20)
              for adding your card which will be credited into your wallet)
            </div>
          </div>
        )}
      </div> */}


      </div>
      :
      <div></div>
}



        


    </div>
  );
};



    export default AddFunds;
