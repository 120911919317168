import httpRequest from "../../ajax";
import { POST_STRIPE_PAYMENT, ERROR } from "../actionTypes";

export const addStripePayment = (dispatch, requestData) => {
  return httpRequest("post", "/wallet/payment/stripePaymentIntent", requestData)
    .then((response) => {
      console.log("Full Response:", response); 

      const clientSecret = response?.data?.client_secret; 
      if (!clientSecret) {
        console.error("Client secret not found in response:", response);
        throw new Error("Invalid response structure");
      }

      const action = { type: POST_STRIPE_PAYMENT, payload: clientSecret };
      console.log("Dispatching Action:", action);
      dispatch(action);
      return action;
    })
    .catch(({ response }) => {
      console.error("Error Response:", response);
      return {
        payload: response?.data?.message || "An unexpected error occurred",
        type: ERROR,
      };
    });
};
