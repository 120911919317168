import httpRequest from "../../ajax";
import {
    POST_ADD_BANK,
    ERROR,
 
} from "../actionTypes";
  

export const addPayment = (dispatch, data) => {
  return httpRequest("post", "/jaraepay/payments/initialize", data)
    .then(({ data: { data } }) => {
    
      const action = { type: POST_ADD_BANK, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));

}
    
  

 