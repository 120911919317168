/* eslint-disable no-empty */
import React, { useState ,useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel } from "@material-ui/core";
// import { GoogleLogin } from "react-google-login";
import Checkbox from "@material-ui/core/Checkbox";
import WBlogo from "../../../../assets/icons/WBlogo.svg";
// import GoogleIcon from "../../../../assets/icons/google-icon.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import {
  loginAction,
   socialMediaRegister,
} from "../../../../redux/actions/authActions";
import { getJobListAction } from "../../../../redux/actions/jobActions";

import { AUTH_LOADING, STOP_AUTH_LOADING } from "../../../../redux/actionTypes";
import { isValidEmail } from "../../../../utils/helpers";
import AuthWrapper from "../AuthWrapper";
import { useHistory } from "react-router-dom"; 
import './index.css';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png'
import FacebookLogin from 'react-facebook-login';

 

const Signin = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    account_type: "referral",
    rememberMe: !!localStorage.rememberMe,
  });

  const sheetRef = useRef();
  const sheetRefLinked = useRef();

  const [showsocial, setShowSocial] = useState(true);
  const history = useHistory();

  const { loading } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const {jobList} = useSelector((store) => store);
  const [sectionLoading, setSectionLoading] = useState(true);

  useEffect(() => {
      Promise.resolve(getJobListAction(dispatch)).finally(() => 
      {
        setTimeout( ()=>{
          setSectionLoading(false);
        }, 1000)
      }
      
      );
    }, [dispatch, 1]);


  const handleChange = ({ target: { id, value, checked } }) => {
    if (id === "rememberMe") {
      if (checked) {
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("rememberMe");
      }
      setState((prevState) => ({ ...prevState, [id]: checked }));
    } else {
      setState((prevState) => ({ ...prevState, [id]: value }));
    }
  };
/*
  const responseGoogle = (response) => {
    console.log("Google Response: "+JSON.stringify(response));
  }*/

  const responseFacebook = (response) => {
    console.log(response);
  }
  
  const handleSubmit = (event) => {
    dispatch({ type: AUTH_LOADING });
    event.preventDefault();
    Promise.resolve(loginAction(state, dispatch)).finally(() => {
      try {
    localStorage.setItem("accountType", "referral");

        const pageToVisit = sessionStorage.getItem("pageToVisit");
        const accountType = localStorage.getItem("accountType");
        if (pageToVisit) {
          sessionStorage.removeItem("pageToVisit");
          const pageToVisitInfo = JSON.parse(pageToVisit);
          if (pageToVisitInfo.accountType === accountType) {
            history.push(pageToVisitInfo.path);
          }
        }
      } catch (error) {}
      dispatch({ type: STOP_AUTH_LOADING });
    });
  };

   const responseGoogle = (response) => {
    if (response.error) return;
     const { accessToken, profileObj } = response;

     const payload = {};
     payload["email"] = profileObj?.email;
     payload["platform_uid"] = profileObj?.googleId;
     payload["platform"] = "google";
     payload["first_name"] = profileObj?.givenName;
     payload["last_name"] = profileObj?.familyName;
     payload["fcm_token"] = accessToken;
     payload["invited_by_code"] = "";
      
      dispatch({ type: AUTH_LOADING });
      Promise.resolve(socialMediaRegister(payload, dispatch)).finally(() => {
      try {
        const pageToVisit = sessionStorage.getItem("pageToVisit");
        const accountType = localStorage.getItem("accountType");
        if (pageToVisit) {
           sessionStorage.removeItem("pageToVisit");
          const pageToVisitInfo = JSON.parse(pageToVisit);
         if (pageToVisitInfo.accountType === accountType) {
             history.push(pageToVisitInfo.path);
          }
          }
      } catch (error) {}
        dispatch({ type: STOP_AUTH_LOADING });
      });


  };
  
  
  const handleSuccess = (data) => {
    console.log("===handleSuccess=="+data.code);
  }

  const handleFailure = (error) => {
    console.log(error);
  }
  const switchToBusiness = () => {
    localStorage.setItem("accountType", "business");
        sessionStorage.setItem("accountType", "business");
    window.location.reload();
  };

  return (
    <>
 
    <AuthWrapper>
      
      <div className="form-container d-flex justify-content-center">
        <div className="auth-form ml-md-4">
          <h4 className="text-center mt-4">Login To Your Account</h4>
          {/* <CustomButton
            icon={GoogleIcon}
            backgroundColor="#fff"
            alt="google icon"
            text="Login with Google"
            additionalClass="my-5"
          /> */}
          {/* <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <CustomButton
                handleClick={renderProps.onClick}
                icon={GoogleIcon}
                backgroundColor="#fff"
                alt="google icon"
                text="Login with Google"
                additionalClass="my-5"
              />
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />  */}
          <CustomButton
            icon={WBlogo}
            alt="logo"
            backgroundColor="black"
            color="#fff"
            text="Switch to workbrook Business"
            additionalClass="mt-3 mb-5"
            handleClick={() => switchToBusiness()}
          />
          <p className="demarcator text-center"> Login</p>

          {
            showsocial ? 
           <form onSubmit={handleSubmit}>
            <CustomInput
              onChange={handleChange}
              type="text"
              label="Email"
              id="email"
              value={state.email}
              placeholder=""
            />
              
            <CustomInput
              onChange={handleChange}
              type="password"
              label="Password"
              id="password"
              placeholder=""
              value={state.password}
              icon={EyeIcon}
            />

            <div className="d-flex justify-content-between align-items-center mt-3 small-text">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    id="rememberMe"
                    onChange={handleChange}
                    checked={state.rememberMe}
                  />
                }
                label="Remember Me"
              />
              <Link to="/forgotPassword">Forgot Password?</Link>
            </div>

            <CustomButton
              type="submit"
              backgroundColor="#3A6ED4"
              color="#fff"
              text="Login"
              loading={loading}
              disabled={!isValidEmail(state.email) || !state.password}
              additionalClass="my-5"
            />

    <p className="demarcator text-center"> Or use Social Logins</p>

      <div class="login-box">
    

      <a href="#" class="social-button w-100 fw-bold" id="google-connect"
      onClick={
        ()=>{
          sheetRef.current.click();
        }
      }
      > <span>Connect with Google</span></a>
			<a href="#" class="social-button w-100 fw-bold" id="linkedin-connect"
      
      onClick={
        ()=>{
          sheetRefLinked.current.click();
        }
      }
      > <span>Connect with LinkedIn</span></a>


  
  
  	</div>


          </form>

                :
      <div class="login-box">
      <a href="#" class="social-button w-100 fw-bold" id="email-connect" onClick={()=>{
        setShowSocial(true)
      }}> <span>Login with email</span></a>
  
      <a href="#" class="social-button w-100 fw-bold" id="google-connect"
      onClick={
        ()=>{
          sheetRef.current.click();
        }
      }
      > <span>Connect with Google</span></a>
			<a href="#" class="social-button w-100 fw-bold" id="linkedin-connect"
      
      onClick={
        ()=>{
          sheetRefLinked.current.click();
        }
      }
      > <span>Connect with LinkedIn</span></a>
		</div>
            }
  
 
          <div style={{display:'none'}}>
            
            <div>
            <GoogleLogin
            clientId="302887164943-r6eh4rg4bglppaa3fu0bdlgkl4mfb1lb.apps.googleusercontent.com"
            buttonText="Login"
            render={renderProps => (
              <button onClick={renderProps.onClick} 
              ref={sheetRef}
               disabled={renderProps.disabled}>This is my custom Google button</button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />
            </div>
            
            
           {/*     
          <FacebookLogin
            appId="620384345321497"
            autoLoad={true}
            fields="name,email,picture"
            onClick={responseFacebook}
            callback={responseFacebook} />  
          */}

         <LinkedIn
          clientId="77o7tob14ug7d2"
          onFailure={handleFailure}
          onSuccess={handleSuccess}
          redirectUri="http://web.workbrook.com/linkedin/callback"
          scope="r_emailaddress"
          style={{
            marginLeft:'5%'
          }}
          
          renderElement={({ onClick, disabled }) => (
            <button onClick={onClick}
            ref={sheetRefLinked}
            disabled={disabled}>
              Custom linkedin element</button>
          )}>  
            <img src={linkedin} alt="Log in with Linked In" style={{ maxWidth: '180px' }} />
          </LinkedIn>
        
         
          </div>
          
          <p className="pt-4 pb-5 mb-0 small-text text-center" style={{fontSize:15}}>
            Don&apos;t have an account? <Link to="/signup">Sign Up</Link>{" "}
          </p>
        </div>
      </div>
   

      {/*
      <div className="inner-main-body p-2 p-sm-3 collapse forum-content show"> 
              {   jobList.jobList && jobList.jobList[0] && jobList.jobList[0].map( (item, index)=>{
                  return(
                   <div className="card   mb-2x mgtio" key={index}> 
                   <div className="card-body p-2 p-sm-3">
                       <div className="media forum-item"> 
                           <div className="media-body">
                               <h6><a href={`${process.env.REACT_APP_WEB_URL}/job-lists-details/${item.id}/apply/no-ref`} target="_blank" data-toggle="collapse" data-target=".forum-content" className="text-body bolded links">{item.role_title}</a></h6>
                             <p className="text-muted"> 
                             <i className="fa fa-building"></i> {item.campany_name} &nbsp;
                             <i className="fa fa-certificate"></i>  {item.industry} &nbsp;
                             <i className="fa fa-calendar"></i>  {item.job_type} 
                             </p> 
                               <p className="text-secondary">
                                    <span dangerouslySetInnerHTML={{ __html: item.about_company.substring(0, 600)+" ..." }} className="inline-block"></span>
                                    <a href={`${process.env.REACT_APP_WEB_URL}/job-lists-details/${item.id}/apply/no-ref`} target="_blank"  className="inline-block links">Apply Now</a>
                               </p>
                               <p className="text-muted"><a href="javascript:void(0)"></a> {item.location} - <span className="text-secondary font-weight-bold">Date Posted: {item.date_created}</span></p>
                           <ul className="d-flex flex-wrap mt-3">
                               {
                             JSON.parse(item.skills_required).map( (skill,index)=>{
                                   return(
                                       <li
                                       key={index.toString()}
                                       className="mr-2 py-1 px-2 mt-1 outlined"
                                       >
                                       {skill}
                                       </li>
                                   )
                               })
                            } 
                           </ul> 
                           </div>
                           <div className="text-muted small text-center align-self-center"> 
                           </div>
                       </div>
                   </div>
                   </div>   
              })     
              }   
      </div>
       */}

    
    </AuthWrapper>
            
      
    </>
    
  
 
    
  );
};

export default Signin;
