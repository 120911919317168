import React, { useEffect, useState } from "react";
import HomeModal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { getJobsAction } from "../../../../redux/actions/jobActions";
import { SectionLoader } from "../../../../components/PageLoader";
import EmptyState from "../../../../components/EmptyState";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { ButtonLoader } from "../../../../components/ButtonLoader";
import { saveBusinessRef } from "../../../../redux/actions/businessActions";
import JobCard from "./JobCard"; 

const AllJobs = () => {
  const [page, setPage] = useState(1);
   const history = useHistory();
  const [totalJobs, setTotalJobs] = useState(0);
    const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("details");
   const { jobId, refLink } = useParams();
  const [loadingMore, setLoadingMore] = useState(false);
  const jobsPerPage = 20;

  const dispatch = useDispatch();
  const { jobsData } = useSelector(({ job }) => job);
  const jobs = jobsData && jobsData.jobs;

  // Calculate total pages
  const totalPages = Math.max(1, Math.ceil(totalJobs / jobsPerPage));

  useEffect(() => {
    const fetchJobs = async () => {
      console.log("Fetching jobs for page:", page);

      setLoading(page === 1);
      setLoadingMore(page > 1);

      try {
        const response = await getJobsAction(dispatch, page, jobsPerPage);

        if (response && response.total) {
          console.log("Total jobs fetched:", response.total);
          setTotalJobs(response.total);
        } else {
          console.log("No total jobs returned from the API.");
          setTotalJobs(0);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }

      setLoading(false);
      setLoadingMore(false);
    };

    fetchJobs();
  }, [dispatch, page, jobsPerPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      console.log("Changing page to:", newPage);
      setPage(newPage);
    }
  };
  const saveForLater = () => {
    const payload = {
      job_id: jobId,
      status: 1,
      refCode: refLink ? refLink : null,
    };
    const saveLater = async () => {
      await saveBusinessRef(dispatch, page, payload);
    };
    saveLater();
  };

  return (
    <div className="row position-relative mt-3 align-items-stretch">
      {jobId && (
        <HomeModal
          setSection={setSection}
          section={section}
          open={true}
          handleClose={() => history.push("/home")}
          isBusiness={
            pathname.includes("biz") || pathname.includes("coy") == true
              ? true
              : false
          }
          isFromCompany={pathname.includes("coy") == false ? true : false}
          saveForLater={saveForLater}
        />
      )}

      {loading && jobs && !jobs[0] && <SectionLoader />}
      {!loading && jobs  && !jobs[0] && (
        <EmptyState text="There are no recommended jobs" />
      )}
      {jobs && jobs[0] && (
        <>
          {jobs.map((job, index) => (
            <div
              style={{
                padding: "10px",
                maxHeight: "280px",
              }}
              key={index.toString()}
              className="col-12 col-md-6"
            >
              <JobCard job={job} />
            </div>
          
          ))} 
        </>
      )}
      {totalPages >= 1 && (
        <div className="container" style={{marginTop: '5%'}}>
        <div className="row clearfix">
          <div className="mx-auto col-md-2 mt-3 w-75"></div>

          <div className="mx-auto col-md-2 mt-3 w-75">
            <CustomButton
              handleClick={() => handlePageChange(page - 1)}
              text={loadingMore ? <ButtonLoader /> : "Prev"}
              disabled={page === 1 || loading}
            />
          </div>

          <div className="col-md-2 text-center" style={{ marginTop: "2.5%" }}>
            <p>
              Page: {page} of {totalPages}
            </p>
          </div>

          <div className="mx-auto col-md-2 mt-3 w-75">
            <CustomButton
              handleClick={() => handlePageChange(page + 1)}
              text={loadingMore ? <ButtonLoader /> : "Next"}
              disabled={page === totalPages || loading}
            />
          </div>

          <div className="mx-auto col-md-2 mt-3 w-75"></div>
        </div>
        </div>
      )}
    </div>
  );
};

export default AllJobs;
