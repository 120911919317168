import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs"; 
export default function PaymentSuccess() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-gradient-to-r from-cyan-500 to-blue-500 py-5">
      <div className="card shadow-lg rounded-4 p-5 text-center w-75 max-w-md border-0">
        
        <BsFillCheckCircleFill className="text-success mb-4" style={{ fontSize: "4rem" }} />
        
      
        <h1 className="font-weight-bold text-dark mt-3">Payment Successful!</h1>
        
      
        <p className="text-muted mt-3">
          Thank you for your payment! Your transaction was processed successfully. You can now proceed with your activities.
        </p>

        <div className="mt-5 d-flex justify-content-center gap-4">
          <button
            className="btn btn-lg btn-success px-5 py-2 rounded-3 shadow-lg hover:shadow-xl transition-all duration-200"
            onClick={() => window.location.href = "/home"}
          >
            Go to Homepage
          </button>
          
        </div>
      </div>
    </div>
  );
}
