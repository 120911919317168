import React, { createContext, useState, useContext } from 'react';

const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  const [clientSecret, setClientSecret] = useState(null);

  return (
    <StripeContext.Provider value={{ clientSecret, setClientSecret }}>
      {children}
    </StripeContext.Provider>
  );
};

export const useStripeContext = () => {
  const context = useContext(StripeContext);
  
  
  if (!context) {
    throw new Error("useStripeContext must be used within a StripeProvider");
  }

  return context;
};
